export function normalizeMap (map) {
  return Array.isArray(map)
    ? map.map(key => ({ key, val: key }))
    : Object.keys(map).map(key => ({ key, val: map[key] }))
}

export const regionReducer = (obj, { code, name }) => {
  obj[code] = name
  return obj
}

export const mapEntryReducer = (obj, { key, val }) => {
  function getVal () {
    const oldVal = obj[key]
    if (!oldVal) return val
    if (Array.isArray(oldVal)) {
      oldVal.push(val)
      return oldVal
    }
    return [oldVal, val]
  }
  obj[key] = getVal()
  return obj
}

export function reduceQuery (query, obj = {}) {
  if (!query || !query.length) return obj
  const replaced = query.replace(/^\?/g, '')
  if (!replaced) return obj
  const split = replaced.split('&')
  const pairs = split.map(entry => {
    const [key, val] = entry.split('=')
    return { key, val: decodeURIComponent(val) }
  })
  if (Array.isArray(obj)) return pairs
  return pairs.reduce(mapEntryReducer, {})
}

export const reduceObjectProperties = (obj, keyMapping) => (prev, key) => {
  const paramKey = keyMapping[key]
  /* eslint-disable no-prototype-builtins */
  if (obj.hasOwnProperty(paramKey)) {
    prev[key] = obj[paramKey]
    return prev
  }
  return prev
}

export const mapObjectProperties = (obj, keyMapping) => {
  if (!obj) return {}
  return Object.keys(keyMapping).reduce(reduceObjectProperties(obj, keyMapping), {})
}

/**
 * throws error if condition is false for local development
 * @param condition
 * @param msg
 */
export function assert (condition, msg) {
  if (process.env.NODE_ENV === 'production') {
    return
  }
  if (!condition) {
    const error = new Error(`[donation-flow-ui] ERROR: ${msg}`)

    const callerLine = ((error.stack.split('\n')[2] || '…').match(
      /\(([^)]+)\)/
      // eslint-disable-next-line no-sparse-arrays
    ) || [, 'not found'])[1]
    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.error(`[donation-flow-ui]  ${callerLine} \n ERROR: ${msg}`)
    }
    throw error
  }
}

export function mapPropertyByPath (obj, path, def) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  const stringToPath = function (path) {
    if (typeof path !== 'string') return path
    const output = []
    path.split('.').forEach(function (item, index) {
      item.split(/\[([^}]+)]/g).forEach(function (key) {
        if (key.length > 0) {
          output.push(key)
        }
      })
    })
    return output
  }
  path = stringToPath(path)
  let current = obj
  for (let i = 0; i < path.length; i++) {
    const subPath = path[i]
    const value = current[subPath]
    if (typeof value === 'undefined') return def
    current = value
  }
  return current
}

export function parseHandlebars (string, context) {
  return string?.replace(/{{.*?}}/g, match => {
    const expression = match.slice(2, -2)
    return mapPropertyByPath(context, expression, 'undefined')
  })
}

export function XOR (a, b) {
  return !!((a ? 1 : 0) ^ (b ? 1 : 0))
}

/**
 * one liner uuid
 * https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
 * @returns uuid
 */
export function uuidv4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export const isMobileBrowser = () => {
  return !!(navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i))
}
